@import "themes";

:host {
  &::-webkit-scrollbar {
    width: rem(6px);
    height: rem(15px);
    cursor: pointer;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: $light-blue-400;
    width: rem(4px);
    max-height: rem(250px);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #c5cae1;
  }

  &::-webkit-resizer {
    display: none;
  }
}

.cursor-pointer {
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.d-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: rem(30px);

  .title {
    margin-bottom: rem(25px);
  }
}

.mat-mdc-tab-group {
  background-color: $white;

  .mat-mdc-tab-header {
    margin-left: rem(20px);

    .mat-mdc-tab-label-container {
      padding-right: rem(20px);

      .mat-mdc-tab-list {
        .mat-mdc-tab-label {
          font-style: normal;
          font-weight: 600;
          font-size: rem(12px);
          line-height: rem(16px);
          color: $blue-200;
        }

        .mat-mdc-tab-label-active {
          color: $sky-blue;
          font-weight: 700;
        }

        .mat-mdc-ink-bar {
          background: $sky-blue;
        }

        .mat-mdc-tab-labels {
          justify-content: center;

          .mat-mdc-tab {
            max-width: rem(200px);

            .mdc-tab__ripple::before {
              background: transparent;
            }

            .mdc-tab__content {
              font-family: Noto-Sans-Georgian-SemiCondensed-Bold;
              font-style: normal;
              font-weight: 600;
              font-size: rem(14px);
              line-height: rem(16px);
              color: $blue-200;
            }
          }
        }
      }
    }

    button {
      box-shadow: none;

      .mat-mdc-tab-header-pagination-chevron {
        border-color: $blue-300;
      }

      &:hover,
      &:disabled {
        background: none;
      }
    }
  }

  mat-mdc-tab-body {
    padding: rem(14px) rem(20px) rem(16px) rem(20px);

    .mat-mdc-tab-body-content {
      > div {
        &:not(:last-child) {
          &::after {
            content: "";
            display: block;
            width: 100%;
            height: 2px;
            margin-top: 10px;
            background-color: $light-blue-200;
          }
        }
      }
    }
  }
}

.mat-mdc-tab-group {
  .mat-mdc-tab-header {
    .mat-mdc-tab-label-container {
      left: 0px;
      width: 100%;

      .mat-mdc-tab-list {
        overflow-x: auto !important;
        transform: none !important;

        .mat-mdc-tab-labels {
          .mat-mdc-tab-label {
            padding: 1.25% !important;
            margin: 0px !important;
            min-width: rem(120px) !important;
          }
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}

.mat-mdc-radio-checked {
  .mat-ripple {
    display: none;
  }

  .mat-ripple-element {
    display: none;
  }

  .mdc-radio__background {
    &::before {
      display: none;
    }

    .mdc-radio__outer-circle,
    .mdc-radio__inner-circle {
      border-color: $blue-200 !important;
    }
  }
}

.mat-mdc-checkbox {
  .mdc-form-field {
    .mdc-checkbox {
      .mdc-checkbox__ripple {
        display: none;
      }
    }
  }
}

.mat-mdc-checkbox-checked {
  .mdc-form-field {
    .mdc-checkbox {
      .mat-ripple {
        display: none;
      }

      .mdc-checkbox__ripple {
        display: none;
      }

      .mdc-checkbox__background {
        border-color: $blue-200 !important;
        background: $blue-200 !important;
      }
    }
  }
}

mat-form-field {
  max-width: rem(450px);
  height: rem(40px);
  margin-top: rem(20px);
  margin-bottom: rem(10px);

  .mat-mdc-text-field-wrapper {
    height: rem(40px);

    .mat-mdc-form-field-flex {
      height: rem(40px);

      .mat-mdc-form-field-infix {
        height: rem(40px);
        min-height: 0;
        padding-top: rem(8px);
        padding-bottom: rem(8px);
        font-size: 15px;
      }

      .mdc-notched-outline {
        .mdc-notched-outline__notch {
          .mdc-floating-label {
            top: rem(18px);

            &.mdc-floating-label--float-above {
              position: relative;
              top: rem(25px);
            }

            mat-label {
              font-size: 14px;
            }
          }
        }
      }

      .mat-mdc-form-field-icon-suffix {
        mat-datepicker-toggle {
          .mat-mdc-icon-button.mat-mdc-button-base {
            &:hover,
            &:active {
              background: transparent !important;
            }

            .mat-mdc-button-persistent-ripple {
              display: none;
            }
          }
        }
      }
    }
  }

  &.textarea {
    height: auto;

    .mat-mdc-text-field-wrapper {
      height: auto;

      .mat-mdc-form-field-flex {
        height: auto;

        .mat-mdc-form-field-infix {
          height: auto;
          min-height: 0;
          padding-top: rem(8px);
          padding-bottom: rem(8px);
          font-size: 15px;
        }
      }
    }
  }

  &.hide-wrapper {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  &.select {
    &.selected {
      .mat-mdc-select-arrow-wrapper {
        display: none;
      }

      mat-icon {
        position: absolute;
        right: rem(-10px);
        margin-top: rem(2px);
        font-size: 21px;
      }
    }
  }
}

.mdc-menu-surface.mat-mdc-autocomplete-panel {
  display: flex;
  flex-direction: column;
  gap: rem(10px);
  padding: rem(10px) 0 !important;
}

.mat-mdc-option {
  .mdc-list-item__primary-text {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: rem(10px);

    img {
      margin-right: rem(5px);
      object-fit: cover;
    }

    span {
      max-width: rem(270px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .mat-pseudo-checkbox {
    display: none;
  }
}

@media only screen and (max-device-width: 1100px) {
  .option-title {
    max-width: rem(180px) !important;
  }
}

@media only screen and (max-device-width: 900px) {
  .option-title {
    max-width: rem(105px) !important;
  }
}

@media only screen and (max-device-width: 750px) {
  .option-title {
    max-width: rem(165px) !important;
  }
}

@media only screen and (max-device-width: 300px) {
  .option-title {
    max-width: rem(105px) !important;
  }
}

@media only screen and (max-device-width: 950px) {
  mat-form-field {
    margin-bottom: rem(30px);
  }

  .overlay-container {
    .toast-container {
      .ngx-toastr {
        width: rem(220px);
      }
    }
  }
}

@media only screen and (min-device-width: 600px) and (max-device-width: 950px) {
  .cdk-overlay-pane {
    width: 50%;
  }
}
@media only screen and (max-device-width: 600px) {
  .cdk-overlay-pane {
    width: 100%;
  }
}

.mat-mdc-form-field-required-marker {
  display: none;
}

.mat-mdc-form-field-error {
  color: $red;
  font-size: 11px;
}

.mat-icon {
  cursor: pointer;
}

.cdk-global-scrollblock {
  overflow: hidden;
}

table {
  width: 100%;
  border: 1px solid $light-blue-200;
  border-radius: 4px;
  border-collapse: separate;
  border-spacing: 0;

  thead {
    th.mat-header-cell {
      color: $blue-400;
      font-weight: 700;
      white-space: nowrap;
      border-right: 1px solid $light-blue-200;
      background: $light-blue-100;
    }
  }

  tbody {
    td.mat-cell {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $blue-300;
      border-right: 1px solid $light-blue-200;
      white-space: nowrap;

      &.sticky {
        position: sticky;
        background: white;
        left: 0;
        z-index: 1;
      }
    }
  }

  th.mat-header-cell,
  td.mat-cell,
  td.mat-footer-cell {
    padding: rem(10px);
  }
}

.mt-10 {
  margin-top: rem(10px);
}

app-icon {
  cursor: pointer;
}

.card {
  height: rem(240px);
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: #201211;
  background: #f0f2f5;
  box-shadow: 0px 2px 8px -3px rgba(0, 0, 0, 0.24);
  border-radius: 11px;
  margin: rem(12px) rem(12px);
  padding-left: rem(13px);
  padding-right: rem(13px);
}

button.default {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-align: center;
  background: #e5ecf7 !important;
  box-shadow: 0px 2px 8px -3px rgba(0, 0, 0, 0.24);
  border-radius: 11px;
  border: none;
}

.mdc-button__label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mat-mdc-raised-button {
  background: #909cee !important;
}

.mat-mdc-raised-button:disabled {
  background: #5767c257 !important;
}

.mat-mdc-progress-spinner circle,
.mat-spinner circle {
  stroke: #909cee;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.footer-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: rem(20px);
}

.get-code-icon {
  .get-code-text {
    position: absolute;
    right: rem(5px);
    bottom: rem(9px);
    background: $dark-grey-200;
    padding: rem(8px);
    border-radius: 5px;
    font-size: 14px;
    z-index: 1;

    &:hover {
      background: $dark-grey-300;
    }

    &:active {
      box-shadow: rem(7px) rem(6px) rem(18px) rem(1px) rgba(0, 0, 0, 0.24);
      transform: translateX(rem(0.5px));
    }
  }
}

.location {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: rem(10px);
  font-size: 13px;
  margin-bottom: rem(10px);

  .logo-and-address {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: rem(10px);
  }

  .date {
    font-size: 10px;
    line-height: 16px;
    color: $dark-grey-500;
  }
}

.header-search-input {
  mat-form-field {
    .mat-mdc-text-field-wrapper {
      background: $light-grey;

      .mat-mdc-form-field-focus-overlay {
        opacity: 0;
      }
    }

    .mat-mdc-input-element {
      position: relative;
      bottom: rem(5px);
    }
  }
}

ngx-pull-to-refresh {
  .ngx-ptr-str-container {
    .ngx-ptr-loadingbar-container {
      margin-top: rem(22px);
    }
  }
}

ngx-skeleton-loader {
  .skeleton-loader {
    background: #6f8eb212 !important;
  }
}

.news.d-container {
  max-width: rem(700px);
  gap: rem(20px);
  font-family: "Noto Sans Georgian SemiCondensed";
  color: #201211;

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
  }

  .news-text,
  .author {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;

    p,
    span,
    strong,
    em,
    abbr,
    del,
    ins,
    sub,
    sup,
    code,
    mark,
    small,
    big,
    u,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ul,
    ol,
    li,
    blockquote,
    pre,
    a,
    div,
    article,
    section,
    aside,
    nav,
    header,
    footer,
    hr,
    br {
      white-space: pre-wrap;
      line-height: normal;
    }

    img,
    video,
    audio {
      max-width: 100%;
      height: 100%;
    }

    iframe {
      width: 100%;
    }
  }

  .swiper-slide {
    max-width: rem(700px);
    max-height: rem(400px);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.my-t-container {
  background: #f5f9fc;
  border-radius: 11px;
  margin: rem(15px);
  padding: rem(20px) rem(20px) rem(30px) rem(20px);
  max-width: rem(850px);
  width: 100%;
  min-height: rem(750px);
  overflow: hidden;

  .my-t-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: rem(25px);
    padding: rem(27px) rem(12px) rem(10px) rem(15px);
    font-family: Noto-Sans-Georgian-SemiCondensed-Bold;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;

    &.wrap {
      flex-wrap: wrap;
    }

    .my-t-size {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: rem(70px);
      width: rem(70px);
      height: rem(44px);
      background: #f0f2f5;
      box-shadow: inset 0px 2px 8px -3px rgba(0, 0, 0, 0.24);
      border-radius: 11px;
    }

    .add-button {
      width: rem(130px);
      height: rem(44px);
    }

    .icon {
      position: relative;
      right: rem(7px);
      top: rem(0.5px);
    }

    .filter-icons {
      display: flex;
      align-items: center;
      gap: rem(10px);
    }
  }

  .my-t-content {
    display: flex;
    flex-direction: column;
    background: #f5f9fc;
    border-radius: 11px;
    overflow: hidden;
    font-family: "Noto-Sans-Georgian-SemiCondensed-Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    overflow: hidden;

    .card {
      display: flex;
      flex-direction: column;
      gap: rem(10px);
      cursor: pointer;
      height: 100%;
      font-size: 14px;
      margin-right: rem(12px);

      &:hover {
        background: $dark-grey-100;
      }
    }

    .job {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: rem(13px);
      padding-top: rem(10px);

      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-family: Noto-Sans-Georgian-SemiCondensed-Bold;
        font-weight: 700;
        font-size: 17px;
        line-height: 1.2;
        margin: rem(15px) 0 rem(15px) 0;

        .published {
          color: $green;
        }

        .pending {
          color: $yellow;
        }

        span {
          margin-top: rem(15px);
        }
      }

      .icons-and-logo {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: rem(25px);

        .action-icons {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          gap: rem(10px);

          .edit {
            &:hover {
              color: $yellow-500;
            }
          }

          .delete {
            color: $red-500;
            margin-right: rem(10px);

            &:hover {
              color: red;
            }
          }
        }
      }

      img {
        position: relative;
        right: rem(8px);
        object-fit: contain;
      }

      .description {
        display: -webkit-box;
        max-width: rem(180px);
        max-height: rem(16px);
        padding-right: rem(10px);
        font-size: 13px;
        line-height: 16px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-all;

        span {
          color: $dark-grey-500;
        }
      }
    }

    .company-and-logo {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-right: rem(10px);
      height: rem(40px);

      .company {
        display: flex;
        align-items: center;
        font-family: Noto-Sans-Georgian-SemiCondensed-Bold;
        font-size: 14px;

        span {
          margin-left: rem(10px);
        }
      }
    }

    .show-more {
      display: flex;
      justify-content: center;
      align-items: center;
      width: rem(80px);
      height: rem(30px);
      background: #e5ecf7;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
      border-radius: 3px;
      cursor: pointer;
    }
  }

  &.my-statements {
    .my-t-content {
      .job {
        align-items: flex-start;
        margin-top: rem(5px);

        .title {
          flex-direction: column;
          align-items: flex-start;
          gap: rem(15px);
          margin-top: 0;

          span {
            margin-top: 0;
          }
        }
      }
    }
  }
}

editor {
  max-width: rem(350px);
  max-height: rem(250px);
}

@media only screen and (max-device-width: 950px) {
  editor {
    max-width: none;
    width: 100%;
  }
}

.tox {
  .tox-notification,
  .tox-statusbar__resize-handle {
    display: none !important;
  }
}

.tox-statusbar__branding,
.tox-notifications-container {
  display: none;
}

.desc-box {
  display: flex;
  flex-direction: column;
  gap: rem(10px);
  padding-top: rem(10px);

  span {
    font-size: 14px;
  }
}

.statement-details-content {
  display: flex;
  flex-direction: column;
  gap: rem(40px);

  .statement-details-item {
    display: flex;
    flex-direction: column;
    gap: rem(7px);

    &.contact-card {
      flex-direction: row;
      gap: rem(40px);
      color: #201211;
      background: #f0f2f5;
      box-shadow: 0px 2px 8px -3px rgba(0, 0, 0, 0.24);
      border-radius: 11px;
      padding: rem(20px);

      img {
        object-fit: cover;
      }

      .contact-box {
        display: flex;
        flex-direction: column;
      }
    }

    .statement-title {
      font-size: 17px;
      font-weight: bold;

      &.contact {
        padding-bottom: rem(15px);
      }
    }

    .statement-text {
      font-size: 15px;

      &.contact {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: rem(15px);

        .box {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: rem(5px);
        }

        a {
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@media only screen and (max-device-width: 850px) {
  .statement-text {
    &.contact {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

@media only screen and (max-device-width: 650px) {
  .contact-card {
    flex-direction: column !important;
    align-items: flex-start;
    gap: rem(25px) !important;

    .box-img {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }

  .statement-text.contact {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
}

.image-and-description-container {
  display: flex;
  flex-direction: column;
  gap: rem(15px);
  margin-bottom: rem(15px);
  flex-wrap: wrap;

  .image {
    width: 100%;
    max-width: rem(250px);
    height: rem(150px);
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 0px 2px 8px -3px rgba(0, 0, 0, 0.24);
    margin-right: rem(20px);
  }

  .description-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: rem(10px);
    gap: rem(5px);

    .icon {
      width: rem(20px);
      height: rem(20px);
    }

    .title {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
}
