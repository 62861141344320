/* You can add global styles to this file, and also import other style files */
@import "assets/styles/themes.scss";
@import "assets/styles/custom.scss";
@import "assets/styles/colors.scss";
@import "assets/styles/fonts.scss";
@import "ngx-toastr/toastr";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "material-icons/iconfont/material-icons.scss";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "NotoSansGeorgian-SemiCondensed-Medium", "Helvetica Neue",
    sans-serif;
  font-size: 16px;
  overflow: hidden;

  ::-webkit-scrollbar {
    width: rem(6px);
    height: rem(8px);
    cursor: pointer !important;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: $light-blue-400;
    width: rem(4px);
    max-height: rem(250px);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #c5cae1;
  }

  ::-webkit-resizer {
    display: none;
  }

  -webkit-tap-highlight-color: transparent;

  .mat-mdc-tab-body-content {
    overflow: hidden;
  }

  --swiper-pagination-color: #6f8eb2;
  --swiper-navigation-color: #6f8eb2;

  // LOADER

  .center-page {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: $light-grey;

    display: flex;
    align-items: center;
    text-align: center;
  }

  .sk-cube-grid {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  .sk-cube-grid .sk-cube {
    width: 33%;
    height: 33%;
    background-color: #333;
    float: left;
    -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
    animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  }
  .sk-cube-grid .sk-cube1 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  .sk-cube-grid .sk-cube2 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  .sk-cube-grid .sk-cube3 {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  .sk-cube-grid .sk-cube4 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }
  .sk-cube-grid .sk-cube5 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  .sk-cube-grid .sk-cube6 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  .sk-cube-grid .sk-cube7 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  .sk-cube-grid .sk-cube8 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }
  .sk-cube-grid .sk-cube9 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }

  @-webkit-keyframes sk-cubeGridScaleDelay {
    0%,
    70%,
    100% {
      -webkit-transform: scale3D(1, 1, 1);
      transform: scale3D(1, 1, 1);
    }
    35% {
      -webkit-transform: scale3D(0, 0, 1);
      transform: scale3D(0, 0, 1);
    }
  }

  @keyframes sk-cubeGridScaleDelay {
    0%,
    70%,
    100% {
      -webkit-transform: scale3D(1, 1, 1);
      transform: scale3D(1, 1, 1);
    }
    35% {
      -webkit-transform: scale3D(0, 0, 1);
      transform: scale3D(0, 0, 1);
    }
  }
}
