@font-face {
  font-family: "Noto-Sans-Georgian-SemiCondensed-Black";
  src: url("../fonts/NotoSansGeorgian_SemiCondensed-Black.ttf") format("TrueType");
  font-weight: 900;
}

@font-face {
  font-family: "Noto-Sans-Georgian-SemiCondensed-Bold";
  src: url("../fonts/NotoSansGeorgian_SemiCondensed-Bold.ttf") format("TrueType");
  font-weight: 700;
}

@font-face {
  font-family: "NotoSansGeorgian-SemiCondensed-Light";
  src: url("../fonts/NotoSansGeorgian_SemiCondensed-Light.ttf") format("TrueType");
  font-weight: 500;
}

@font-face {
  font-family: "NotoSansGeorgian-SemiCondensed-Medium";
  src: url("../fonts/NotoSansGeorgian_SemiCondensed-Medium.ttf") format("TrueType");
  font-weight: 900;
}

@font-face {
  font-family: "Noto-Sans-Georgian-SemiCondensed-Regular";
  src: url("../fonts/NotoSansGeorgian_SemiCondensed-Regular.ttf") format("TrueType");
  font-weight: 300;
}
