@import "colors";

@mixin theme-light {
  --header-background-color: #{$white};
  --body-background-color: #{$light-grey};
}

@mixin theme-dark {
  --header-background-color: #{$black-500};
  --body-background-color: #{$black-500};
}

$base-font-size: 16px;

@function rem($value) {
  @return calc($value / $base-font-size) + rem;
}

:root {
  * {
    box-sizing: border-box;
    font-family: 'Noto-Sans-Georgian-SemiCondensed-Regular';
    color: #201211;
  }
  body,
  body[theme="light"] {
    @include theme-light;
    background-color: var(--body-background-color);
  }

  body[theme="dark"] {
    @include theme-dark;
    background-color: var(--body-background-color);
  }

  @media only screen
  and (min-device-width : 320px)
  and (max-device-width : 480px)
  {
    body {
      font-size: 14px;
    }
  }

  @media only screen
  and (min-device-width : 360px)
  and (max-device-width : 640px)
  {
    body {
      font-size: 16px;
    }
  }

  @media only screen
  and (min-device-width : 768px)
  and (max-device-width : 1024px)
  {
    body {
      font-size: 18px;
    }
  }

  @media only screen
  and (min-width: 1370px)
  and (max-width: 1605px)
  {
    body {
      font-size: 20px;
    }
  }
}
