$white: #ffffff;
$black: #201211;
$blue: #1a204f;
$purple: #4b72ff;
$red: #f44336;
$yellow: #d89600;
$light-yellow: #f7e9c7;
$green: #239664;
$grey: #e5e5e5;
$light-grey: #f5f5f5;
$sky-blue: #00aeef;
$light-purple: #7895d9;
$default-blue: #4162AC;

$purple-100: #f6f8ff;
$purple-200: #edf1ff;
$purple-300: #c9d5ff;
$purple-400: #a5b8ff;
$purple-500: #3c59ca;

$dark-purple-100: #e8eaf3;
$dark-purple-200: #c5cae1;
$dark-purple-300: #8b95c3;
$dark-purple-400: #5968a9;
$dark-purple-500: #5d6590;

$red-100: #fdf5f3;
$red-200: #fcebe7;
$red-300: #f9d6cf;
$red-400: #f3ac9f;
$red-500: #ec8370;

$green-100: #d6f2e6;
$green-200: #ace4cd;
$green-300: #83d7b3;
$green-400: #59c99a;
$green-500: #30bc81;

$yellow-100: #f6ecd1;
$yellow-200: #eedaa4;
$yellow-300: #e5c776;
$yellow-400: #ddb549;
$yellow-500: #d4a21b;

$black-100: #f3f5f8;
$black-200: #d1d2dc;
$black-300: #a3a6b9;
$black-400: #5f6384;
$black-500: #222222;

$light-blue-100: #f3f6f9;
$light-blue-200: #e7ecf2;
$light-blue-300: #cfd9e5;
$light-blue-400: #b7c7d9;
$light-blue-500: #9fb4cc;

$blue-100: #6f8eb2;
$blue-200: #3f6999;
$blue-300: #0f437f;
$blue-400: #0b325f;
$blue-500: #092749;

$dark-blue-100: #cfd9e5;
$dark-blue-200: #9fb4cc;
$dark-blue-300: #6f8eb2;
$dark-blue-400: #3f6999;
$dark-blue-500: #0f437f;

$sky-blue-100: #cce8f2;
$sky-blue-200: #66b9d9;
$sky-blue-300: #008bbf;
$sky-blue-400: #296e9a;
$sky-blue-500: #184a71;

$grey-100: #f8f8fa;
$grey-200: #f1f1f3;
$grey-300: #e4e4e6;
$grey-400: #e9e9ea;
$grey-500: #dcdcdc;

$dark-grey-100: #e3e3e5;
$dark-grey-200: #c7c7cc;
$dark-grey-300: #ababb2;
$dark-grey-400: #8f8f99;
$dark-grey-500: #73737f;
